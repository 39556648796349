import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Card from "../../../components/Card";
import Details from "./Details";
import Filters from "../../../components/Filters";
import Form from "../../../components/Form";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import NotFound from "../../NotFound";
import Panel from "./Panel";
import Settings from "./Settings";
import Table from "./Table";
import cn from "classnames";
import { makeApiCall } from "../../../lib/api";
import styles from "./CustomerList.module.sass";

const navigation = ["Active", "New"];

const CustomerList = ({ userProfile }) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [citizens, setCitizens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [nodata, setNodata] = useState(false);


  const navigate = useNavigate();
  // console.log(navigate)
  const { pathname } = useLocation();
  // console.log(pathname)
  const inc = 10;

  const handleSubmit = (e) => {
    alert();
  };
  const getIndividualUsers = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/housing?size=${fetchLimit}&page=${page}`
    );
    // console.log(response)
    if (response.status === 200) {
      if (response?.data?.length === 0) {
        setNodata(true);
      }
      if (citizens.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      if (response?.data?.length === 0) {
        setNodata(true);
      }
      setCitizens(response?.data);
      // console.log(citizens)
      setIsLoading(false);
    }
  };
  const getEachUserHouse = async () => {
    // e.preventDefault()
    setIsLoading(true);
    const response = await makeApiCall(
      `/housing/${userProfile[0].user_id}?size=${fetchLimit}&page=${page}`
    );
    // console.log(response)
    if (response.status === 200) {
      if (response?.data?.length === 0) {
        setNodata(true);
      }
      if (citizens.length >= response.meta.total_items) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
      setCitizens(response?.data);
      // console.log(citizens)
      setIsLoading(false);
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (pathname === "/user-profile") {
      getEachUserHouse();
    } else {
      getIndividualUsers();
    }
  }, [fetchLimit]);
  // console.log(nodata, 'Nodata')

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore === false) return;
        // Check if there is more data to fetch
        // Increment page and fetchLimit
        // setPage((prevPage) => prevPage + 1)
        // console.log('ddd', hasMore)
        setFetchLimit((prevFetched) => prevFetched + inc);
      }
    };
    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);
  return (
    <>
      <Card
        className={styles.card}
        title={pathname === "/user-profile" ? "" : "Houses"}
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
                width: "100%",
              }}
            >
              <Link
                className={cn("button-stroke button-small", styles.button)}
                to=""
                onClick={goBack}
              >
                <Icon name="arrow-left" size="24" />
                <span>Back</span>
              </Link>
            </div>
            {/* <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder='Search by name or email'
              type='text'
              name='search'
              icon='search'
            /> */}
            {/* <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div> */}
            {/* <Filters
              className={styles.filters}
              title='Showing 10 of 24 customer'
            >
              <Settings />
            </Filters> */}
          </>
        }
      >
        <div className={cn(styles.row, { [styles.flex]: visible })}>
          <Table
            className={styles.table}
            activeTable={visible}
            setActiveTable={setVisible}
            citizens={citizens}
            isLoading={isLoading}
            nodata={nodata}
            // response={response}
          />

          {/* <Details
            className={styles.details}
            onClose={() => setVisible(false)}
          /> */}
          {/* {isLoading ? (
            ''
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <button
                className={cn('button-stroke button-small', styles.button)}
                // onClick={handleScroll}
              >
                Load more
              </button>
            </div>
          )} */}
        </div>
      </Card>
      {/* <Panel /> */}
    </>
  );
};

export default CustomerList;
